export const header = () => {
  const headerEl = document.getElementById("header");
  let posY;

  window.addEventListener("scroll", () => {
    posY = window.scrollY;

    if (posY < 10) {
      headerEl.classList.remove("is-scroll");
    } else {
      headerEl.classList.add("is-scroll");
    }
  });
};
